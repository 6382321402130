@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@800&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --label-label-16: Montserrat;
  --font-sora: Sora;
  --font-poppins: Poppins;

  /* font sizes */
  --button-label-button-18-size: 18px;
  --font-size-13xl: 32px;
  --paragraph-paragraph-14-primary-size: 14px;
  --font-size-base-4: 15.4px;
  --font-size-mid-1: 17.1px;
  --font-size-29xl: 48px;
  --button-label-button-12-size: 12px;
  --header-h2-24pt-size: 24px;
  --header-h1-28pt-size: 28px;
  --label-label-16-size: 16px;
  --header-h3-20pt-size: 20px;
  --font-size-53xl: 72px;
  --font-size-mid: 17px;

  /* Colors */
  --color-whitesmoke: #f7f7f7;
  --neutrals-white-0: #fefefe;
  --neutrals-black: #090909;
  --color-gray-300: rgba(1, 2, 14, 0.4);
  --color-gray-200: rgba(255, 255, 255, 0.2);
  --color-gray-600: rgba(0, 0, 0, 0.08);
  --color-gray-700: rgba(0, 0, 0, 0.4);
  --color-gray-400: rgba(0, 0, 0, 0.12);
  --color-gray-800: rgba(6, 0, 13, 0.98);
  --brand-v400: #5801b2;
  --color-white: #fff;
  --neutrals-g30: #ddd;
  --brand-v300-primary: #7d01fd;
  --color-black: #000;
  --neutrals-g50-light: #a6a6a6;
  --brand-v50: #f3e5fe;
  --links-b50: #deebff;
  --links-b300-primary: #0065ff;
  --success-g50: #e6f6f3;
  --success-g300-primary: #00a38a;
  --alert-o50: #fff4e5;
  --alert-o300-primary: #fd9100;
  --error-r50: #fde6ed;
  --error-r300-primary: #eb024c;
  --color-silver: #bbb;
  --success-g400: #0b7060;

  /* Gaps */
  --gap-base: 16px;
  --gap-lg-8: 18.8px;
  --gap-sm-7: 13.7px;
  --gap-5xs: 8px;
  --gap-xl-9: 20.9px;
  --gap-base-2: 15.2px;
  --gap-21xl: 40px;
  --gap-13xl: 32px;
  --gap-239xl: 258px;
  --gap-xs: 12px;
  --gap-5xl: 24px;
  --gap-29xl: 48px;
  --gap-9xs: 4px;
  --gap-lgi: 19px;
  --gap-37xl: 56px;
  --gap-xl: 20px;
  --gap-8xs: 5px;

  /* Paddings */
  --padding-29xl: 48px;
  --padding-45xl: 64px;
  --padding-xl: 20px;
  --padding-5xl: 24px;
  --padding-17xl: 36px;
  --padding-base: 16px;
  --padding-5xs: 8px;
  --padding-xl-5: 20.5px;
  --padding-3xl-8: 22.8px;
  --padding-77xl: 96px;
  --padding-21xl: 40px;
  --padding-13xl: 32px;
  --padding-xs: 12px;
  --padding-125xl: 144px;
  --padding-61xl: 80px;
  --padding-9xs: 4px;

  /* Border radiuses */
  --br-base: 16px;
  --br-5xs: 8px;
  --br-6xs-8: 6.8px;
  --br-66xl-5: 85.5px;
  --br-5xs-6: 7.6px;
  --br-76xl: 95px;
  --br-21xl: 40px;
  --br-5xl: 24px;
  --br-6xs: 7px;
  --br-101xl: 120px;
  --br-13xl: 32px;
  --br-29xl: 48px;
  --br-xs: 12px;
}

/* the slides */
#slider1 .slick-slide {
  margin-right: 30px;
}
#slider2 .slick-track {
  display: flex;
  gap: 70px;
}
#slider2 .slick-slide {
  margin-left: -35px;
  margin-right: 35px;
}
#slider2 .slick-dots {
  bottom:-70px;
}
#slider2 .slick-dots li {
  width:40px;
  height: 40px;
}
#slider2 .slick-dots li button {
  width:40px;
  height: 40px;
}
#slider2 .slick-dots li button::before {
  width:40px;
  height:40px;
  font-size: 12px;
  color: #7D01FD;
}
#slider3 .slick-track {
  display: flex;
  gap: 70px;
}
#slider3 .slick-slide {
  margin-left: -35px;
  margin-right: 35px;
}
#slider3 .slick-dots {
  bottom:-70px;
}
#slider3 .slick-dots li {
  width:40px;
  height: 40px;
}
#slider3 .slick-dots li button {
  width:40px;
  height: 40px;
}
#slider3 .slick-dots li button::before {
  width:40px;
  height:40px;
  font-size: 12px;
  color: #7D01FD;
}

@media only screen and (max-width: 900px) {
  #slider1 .slick-track {
    display: flex;
    gap: 30px;
  }
  #slider1 .slick-slide {
    margin-left: -15px;
    margin-right: 15px;
  }
  #slider2 .slick-track {
    display: flex;
    gap: 60px;
  }
  #slider2 .slick-slide {
    margin-left: -30px;
    margin-right: 30px;
  }
}
