body{
  overflow-x: hidden;
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}
.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:35%;
  font-weight: bold;
  color:#ffffff;
}
.core{
  border: 1px solid var(--color-gray-400);
  background-color: #ffffff;
  margin: 25px 10% 25px 10%;
  border-radius: 15px;
  padding:50px 80px 50px 80px;
  font-size: 15px;
  text-align: left;
}
.main{
  width:75%;
  margin-left: 12.5%;
  margin-top: 80px;
}
.planFlex{
  display: flex;
  flex-direction: row;
  gap:40px;
  margin-bottom: 50px;
}
.planItem{
  display: flex;
  flex-direction: row;
  gap:50px;
  background-color: #ffffff;
  border: 1px solid var(--color-gray-400);
  border-radius: 20px;
  padding:5px;
  width:33.33%;
}
.planItemLast{
  display: flex;
  flex-direction: row;
  gap:50px;
  background-color: #7D01FD  ;
  border: 1px solid var(--color-gray-400);
  border-radius: 20px;
  padding:5px;
  width:33.33%;
}

.itemLeft img{
  height: 100%;
  width:200px;
}
.itemRight{
  text-align: left;
  margin-left: -10%;
  padding-top: 5px;
}
.itemRight h6{
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 24px;
}
.majorTitle{
  font-size: 20px !important;
}
.itemRight div{
  background-color: #F3F3F3;
  border-radius: 10px;
  padding:0.5px 10px;
  margin-bottom: 8px;
}
.itemRight div p{
  font-size: 15px;
  font-weight: bold;
}
.itemRight div ul{
  font-size: 12.5px;
  padding-left: 10px;
}
.itemRight div ul li::marker{
  font-size: 10px;
}
.heading{
  font-size: 40px;
  margin-bottom: 80px;
}
.params{
  width:85%;
  margin-left: 7.5%;
}
.paramFlex{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.paramFlex2{
  display: flex;
  flex-direction: row;
  gap: 50px;
  width:75%;
  margin-left: 17.5%;
  margin-top: 50px;
}
.paramItem{
  width:33.3%;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding: 10px 40px;
}
.mainIcon{
  margin-top: 20px;
}
.paramItem h6{
  margin:0px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.paramList{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.paramList p{
  font-size: 16px;
}
.more{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #EB024C;
  cursor: pointer;
}
.paramBtn{
  background-color: #7D01FD;
  border-radius: 30px;
  color:#ffffff;
  width: 30%;
  font-size: 20px;
  margin-left: 35%;
  margin-top: 50px;
  padding: 10px 0px;
  cursor: pointer;
}
.mainItem{
  text-align: left;
  margin-bottom: 80px;
}
.mainTop{
  display: flex;
  flex-direction: row;
  gap:20px;
  padding-left: 30px;
  margin-bottom: 20px;
}
.mainHead p:first-child{
  font-weight: bold;
  font-size: 22px;
}
.accordion{
  border-radius: 15px !important;
}
.accordion2{
  border-radius: 15px 15px 0px 0px !important;
}
.accordion3{
  border-radius: 0px 0px 15px 15px !important;
}
.aHead{
  padding-left: 30px;
}
.aHeadText{
  font-weight: bold !important;
  font-size: 18px !important;
}
.aItem{
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  width:33.3%;
}
.aItem p{
  font-size: 15px;
  margin-left: 15px;
}
.aGroup{
  display: flex;
  flex-direction: row;
}
.aGroup2{
  display: flex;
  flex-direction: row;
  width:92.5%;
}
.more1{
  font-size: 15px;
  color:#EB024C;
  text-align: center;
}
.more2{
  font-size: 15px;
  color: #FD9100;
  text-align: center;
}
.more3{
  font-size: 15px;
  color: #00A38A;
  text-align: center;
}
.more4{
  font-size: 15px;
  color: #7D01FD;
  text-align: center;
}
.more5{
  font-size: 15px;
  color: #0065FF;
  text-align: center;
}

@media only screen and (max-width: 900px) {
  .banner{
    display: none;
  }
  .main{
    width:80%;
    margin-left: 10%;
    margin-top: 0px;
    padding-top: 120px;
  }
  .mainTop{
    padding-left: 0px;
  }
  .mainHead p:first-child{
    position: relative;
    top:-10px;
  }
  .aItem{
    margin-left: 3%;
    width:100%;
  }
  .aGroup{
    flex-direction: column;
  }
  .aGroup2{
    flex-direction: column;
  }
}
