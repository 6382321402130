body{
  overflow-x: hidden;
}

.titleText2 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.titleText2 {
  position: relative;
  left: 10%;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--neutrals-g30);
  box-sizing: border-box;
  width: 80%;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-13xl);
  text-align: left;
  font-size: var(--header-h1-28pt-size);
  top:-100px;
}


.aboutUs {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--neutrals-black);
  font-family: var(--label-label-16);
}

.cinput{
  position: relative;
  width:45% !important;
  height:25px;
  border-radius:5px;
  outline:none;
  border: 1px solid rgb(200,200,200);
  padding: 5px 10px 5px 10px;
  margin-right: 10px;
  min-width: 350px;
}
.bookBtn{
  outline: none;
  border:none;
  background-color: blueviolet;
  border-radius: 5px;
  padding:10px 20px;
  cursor: pointer;
  color: white;
  align-self: center;
  margin-top: 20px;
  font-size: 22px;
}
.cp{
  font-size:18px;
  line-height:1;
  margin-top:-20px;
}
.cp2{
  font-size:18px;
  line-height:1;
  margin-top:-5px;
}
.cdiv{
  width:'100%' !important;
  display:'flex' !important;
  flex-direction:'row' !important;
  gap:15 !important;
  margin-top:30 !important;
}

@media only screen and (max-width: 900px) {
  .titleText2{
    border-radius: var(--br-5xl);
    background-color: var(--neutrals-white-0);
    border: 1px solid var(--neutrals-g30);
    box-sizing: border-box;
    width: 90%;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-start;
    left:5%;
    padding:10px;
    margin-top: 200px;
  }
  .cp{
    font-size: 14px;
    margin-top: -10px;
  }
  .cp2{
    font-size: 14px;
    margin-top: 0x;
  }
  .cdiv{
    width:'100%';
    display:'flex';
    flex-direction:'column';
    gap:15;
    margin-top:30;
  }
  .cinput{
    width:80% !important;
    min-width: 0px;
  }

}
