body{
  overflow-x: hidden;
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}
.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:38%;
  font-weight: bold;
  color:#ffffff;
}
.core{
  border: 1px solid var(--color-gray-400);
  background-color: #ffffff;
  margin: 25px 10% 25px 10%;
  border-radius: 15px;
  padding:50px 80px 50px 80px;
  font-size: 15px;
  text-align: left;
}


@media only screen and (max-width: 900px) {
  .banner{
    border-radius: 25px;
  }
  .banner img{
    height: 150px;
    border-radius: 25px;
  }
  .title{
    top:160px;
    left:30%;
    font-size: 16px;
  }
  .core{
    padding:30px 20px 30px 20px;
  }
}
