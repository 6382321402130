body{
  overflow-x: hidden;
}
.mobileAppStoreBadge,
.mobileAppStoreBadge1 {
  position: relative;
  border-radius: 6.97px;
  height: 57.7px;
  mix-blend-mode: normal;
  cursor: pointer;
}
.mobileAppStoreBadge {
  width: 189.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.mobileAppStoreBadge1 {
  width: 168.5px;
}
.mobileAppStoreBadgeParent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}

.lremIpsumDinelagen {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 140%;
}
.lremIpsumDinelagenMonoceptWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xl);
}
.lremIpsumDinelagen1 {
  align-self: stretch;
  position: relative;
  font-size: var(--button-label-button-18-size);
  letter-spacing: -0.01em;
  line-height: 24px;
}
.value1Parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xl);
  gap: var(--gap-5xs);
}
.frameParent,
.titleText {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent {
  align-self: stretch;
  flex-direction: row;
  gap: var(--gap-5xl);
  font-size: var(--header-h2-24pt-size);
}
.titleText {
  position: relative;
  left: 10%;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--neutrals-g30);
  box-sizing: border-box;
  width: 80%;
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-13xl);
  text-align: left;
  font-size: var(--header-h1-28pt-size);
  top:-100px;
}
.imageFromRawpixelId1255100Icon {
  align-self: stretch;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  height: 360px;
  flex-shrink: 0;
  object-fit: cover;
}
.lremIpsumDinelagen4 {
  align-self: stretch;
  position: relative;
  font-size: var(--button-label-button-18-size);
  letter-spacing: -0.01em;
  line-height: 24px;
  color: var(--color-black);
}
.personNameParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-5xl);
  gap: var(--gap-5xs);
}
.titleText1,
.titleTextGroup,
.titleTextParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.titleText1 {
  flex: 1;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  border: 1px solid var(--neutrals-g30);
  overflow: hidden;
  flex-direction: column;
}
.titleTextParent {
  position: relative;
  left: 0px;
  width: 80%;
  flex-direction: row;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--header-h2-24pt-size);
  margin-left: 10%;
  margin-top: 50px;
}
.titleTextGroup{
  position: relative;
  left: 0px;
  width: 80%;
  flex-direction: row;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--header-h2-24pt-size);
  margin-left: 10%;
  margin-top: 50px;
}
.herTitle,
.herTitle1 {
  position: relative;
  left: 0%;
  line-height: 120%;
  text-transform: capitalize;
  font-weight: 600;
  color: var(--color-black);
  display: inline-block;
  width: 90%;
  margin-left: 5%;
}

.herTitle1{
  margin-top: 80px;
}
.logo{
  position: relative;
  line-height: 100%;
  font-weight: 800;
}
.button {
  border-radius: var(--br-base);
  background-color: var(--brand-v400);
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  text-align: left;
}
.button{
  display: flex;
  flex-direction: row;
  align-items: center;
}


.aboutUs {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--neutrals-black);
  font-family: var(--label-label-16);
}

.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:43%;
  font-weight: bold;
  color:#ffffff;
}


@media only screen and (max-width: 900px) {
  .banner{
    display: none;
  }
  .titleText{
    border-radius: var(--br-5xl);
    background-color: var(--neutrals-white-0);
    border: 1px solid var(--neutrals-g30);
    box-sizing: border-box;
    width: 90%;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-start;
    left:5%;
    padding:10px;
    margin-top: 200px;
  }
  .lremIpsumDinelagenMonoceptWrapper{
    font-size: 18px;
  }
  .frameParent {
    flex-direction: column;
    gap: var(--gap-5xl);
    font-size: 20px;
  }
  .titleTextGroup,
  .titleTextParent {
    flex-direction: column;
    gap: var(--gap-13xl);
    font-size: var(--header-h2-24pt-size);
    margin-top: 50px;
    width:90%;
    margin-left: 5%;
  }
  .herTitle,
  .herTitle1{
    font-size: 32px;
  }
  .titleTextMain{
    display: none;
  }

}
