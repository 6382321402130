  body{
    overflow-x: hidden;
  }
  .tabs {
    position: relative;
    line-height: 100%;
    font-weight: 800;
    letter-spacing: 0.01em;
    font-weight: 600;
    cursor: pointer;
    color:#000000;
  }
  .tabs2{
    position: relative;
    line-height: 100%;
    font-weight: 800;
    letter-spacing: 0.01em;
    font-weight: 600;
    cursor: pointer;
    color:#37BA45;
    border: 1px solid #37BA45;
    border-radius: 7px;
    padding:12px 20px;
  }
  .span{
    position: relative;
    bottom: 5px;
    margin-left: 10px;
  }
  .navbarLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 0px 15px 0px;
    gap: 30px;
  }

  .navbar,
  .navbarLinkParent {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .navbarLinkParent {
    justify-content: center;
    gap: var(--gap-base);
    font-size: var(--button-label-button-18-size);
    color: var(--color-white);
    font-family: var(--label-label-16);
  }
  .navbar {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffff;
    backdrop-filter: blur(12px);
    border-bottom: 1px solid var(--color-gray-400);
    box-sizing: border-box;
    width:100%;
    justify-content: space-between;
    padding: 0 var(--padding-29xl) 0 var(--padding-45xl);
    font-size: var(--font-size-13xl);
    color: var(--neutrals-white-0);
    font-family: var(--font-sora);
    z-index: 10;
  }
  
  .navIcon {
    width:160px;
    height:40px;
    cursor: pointer;
  }
  .drawer{
    display: none;
  }
  .drawerCross{
    display: none;
  }
  
  @media only screen and (max-width: 900px) {
    .navbar{
      padding: 15px 5% 5px 5%;
    }
    .navbarLinkParent {
      display: none;
    }
    .drawer{
      display: block;
      cursor: pointer;
    }
    .drawerCross{
      display: block;
      cursor: pointer;
    }
    .drawerCross img{
      width: 160%;
    }
    .drawer img{
      width:100%;
      height: 100%;
    }
    .navIcon {
      width:160px;
      height:40px;
      cursor: pointer;
      position: relative;
      left: -15%;
      top:-5px;
    }
    .mobileDrawer{
      background-color: #ffffff;
      width:100%;
      height:800px;
      position: absolute;
      left:0px;
      top:80px;
      z-index:100;
      color:#000000;
      border-bottom: 1px solid var(--color-gray-400);;
    }
    .navbarLink {
      flex-direction: column;
      padding: 50px 5% 50px 5%;
      gap: 40px;
      align-items: normal;
      justify-content: left;
    }
    .tabs {
      border-bottom: 1px solid rgba(150,150,150,0.5);
      text-align: left;
      padding-bottom: 15px;
      font-size: 20px;
    }
    .tabs2{
      padding:12px 20px;
    }
    .span{
      position: relative;
      bottom: 5px;
      margin-left: 10px;
      font-size: 16px;
    }
  }
  