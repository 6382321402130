  body{
    overflow-x: hidden;
  }
  .takeYourPathToPreventiveWParent {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0px;
    left: 0px;
    background-color: var(--brand-v400);
    width:100%;
    justify-content: center;
    padding: var(--padding-77xl) 0;
    box-sizing: border-box;
    gap: var(--gap-21xl);
    color: var(--color-white);
    font-family: var(--font-poppins);
    margin-top: 100px;
  }
  .bookYourDna {
    align-self: stretch;
    position: relative;
    line-height: 110%;
    font-weight: 600;
  }
  .mobileAppStoreBadgeParent {
    flex-direction: row;
    gap: var(--gap-5xl);
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .mobileAppStoreBadge,
  .mobileAppStoreBadge1 {
    position: relative;
    border-radius: 6.97px;
    height: 57.7px;
    mix-blend-mode: normal;
    cursor: pointer;
  }
  .mobileAppStoreBadge {
    width: 189.4px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .mobileAppStoreBadge1 {
    width: 168.5px;
  }
  .mainFooter{
    background-color: #4C009C;
    display: flex;
    flex-direction: row;
    padding: 80px 10% 40px 10%;
    color: #ffffff;
  }
  .info{
    width:20%;
    text-align: left;
    font-size: 15px;
  }
  .info img{
    width: 200px;
    position: relative;
    right: 10px;
  }
  .info p{
    margin-top: 20px;
  }
  .info div{
    display: flex;
    flex-direction: row;
    gap:20px;
    position: relative;
    left: 10px;
    margin-top: 20px;
  }
  .info div img{
    width:25px;
    cursor: pointer;
  }
  .address{
    width:20%;
    text-align: left;
    font-size: 15px;
    margin-left: 20%;
  }
  .address p{
    line-height: 0.8;
  }
  .head{
    margin-bottom: 30px;
  }
  .links{
    width:20%;
    text-align: left;
    font-size: 15px;
    margin-left: 25%;
  }
  .links p{
    line-height: 0.8;
    cursor: pointer;
  }
  .copyright{
    background-color: var(--brand-v400);
    color:rgb(200,200,200);
    font-size: 12px;
    text-align: center;
    padding:20px 0px 20px 0px;
  }
  
  @media only screen and (max-width: 900px) {
    .takeYourPathToPreventiveWParent{
      height: 400px;
    }
    .bookYourDna{
      font-size: 30px;
    }
    .mobileAppStoreBadgeParent {
      flex-direction: column;
      gap: var(--gap-5xl);
    }
    .mainFooter{
      flex-direction: column;
    }
    .info{
      width:100%;
    }
    .address{
      width:100%;
      margin-left: 0%;
      margin-top: 50px;
    }
    .links{
      width:100%;
      margin-left: 0%;
      margin-top: 50px;
    }
  }
  