body{
  overflow-x: hidden;
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}
.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:45%;
  font-weight: bold;
  color:#ffffff;
}
.core{
  border: 1px solid var(--color-gray-400);
  background-color: #ffffff;
  margin: 25px 10% 25px 10%;
  border-radius: 15px;
  padding:50px 80px 50px 80px;
  font-size: 15px;
  text-align: left;
}
.main{
  width:85%;
  margin-left: 7.5%;
  position: relative;
  top:-80px;
}
.planFlex{
  display: flex;
  flex-direction: row;
  gap:40px;
  margin-bottom: 50px;
}
.planItem{
  display: flex;
  flex-direction: row;
  gap:50px;
  background-color: #ffffff;
  border: 1px solid var(--color-gray-400);
  border-radius: 20px;
  padding:5px;
  width:33.33%;
}
.planItemLast{
  display: flex;
  flex-direction: row;
  gap:50px;
  background-color: #7D01FD  ;
  border: 1px solid var(--color-gray-400);
  border-radius: 20px;
  padding:5px;
  width:33.33%;
}

.itemLeft img{
  height: 100%;
  width:200px;
}
.itemRight{
  text-align: left;
  margin-left: -10%;
  padding-top: 5px;
}
.itemRight h6{
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 22px;
}
.majorTitle{
  font-size: 20px !important;
}
.itemRight div{
  background-color: #F3F3F3;
  border-radius: 10px;
  padding:0.5px 10px;
  margin-bottom: 8px;
}
.itemRight div p{
  font-size: 15px;
  font-weight: bold;
}
.itemRight div ul{
  font-size: 12.5px;
  padding-left: 10px;
}
.itemRight div ul li::marker{
  font-size: 10px;
}
.heading{
  font-size: 40px;
  margin-bottom: 80px;
}
.params{
  width:85%;
  margin-left: 7.5%;
}
.paramFlex{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.paramFlex2{
  display: flex;
  flex-direction: row;
  gap: 50px;
  width:75%;
  margin-left: 17.5%;
  margin-top: 50px;
}
.paramItem{
  width:33.3%;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding: 10px 40px;
}
.mainIcon{
  margin-top: 20px;
}
.paramItem h6{
  margin:0px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.paramList{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.paramList p{
  font-size: 16px;
}
.more{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #EB024C;
  cursor: pointer;
}
.more2{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #FD9100;
  cursor: pointer;
}
.more3{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #00A38A;
  cursor: pointer;
}
.more4{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #7D01FD;
  cursor: pointer;
}
.more5{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #0065FF;
  cursor: pointer;
}
.paramBtn{
  background-color: #7D01FD;
  border-radius: 30px;
  color:#ffffff;
  width: 30%;
  font-size: 20px;
  margin-left: 35%;
  margin-top: 50px;
  padding: 10px 0px;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .banner{
    display: none;
  }
  .main{
    top:120px;
  }
  .planFlex{
    flex-direction: column;
    gap:40px;
    margin-bottom: 40px;
  }
  .planItem{
    gap:20px;
    padding:5px;
    width:100%;
  }
  .planItemLast{
    gap:20px;
    padding:5px;
    width:100%;
  }
  
  .itemLeft img{
    height: 100%;
    width:100px;
  }
  .itemRight{
    margin-left: -2%;
  }
  .itemRight h6{
    font-size: 14px;
  }
  .majorTitle{
    font-size: 10px !important;
  }
  .itemRight div p{
    font-size: 10px;
  }
  .itemRight div ul{
    font-size: 8px;
    padding-left: 10px;
  }
  .itemRight div ul li::marker{
    font-size: 8px;
  }
  .heading{
    font-size: 25px;
    margin-bottom: 40px;
    margin-top: 230px;
  }
  .paramFlex{
    flex-direction: column;
    gap: 50px;
    width:85%;
  }
  .paramFlex2{
    flex-direction: column;
    gap: 50px;
    width:85%;
    margin-left: 0%;
    margin-top: 50px;
  }
  .paramItem{
    width:100%;
    padding: 10px 20px;
  }
  .mainIcon{
    margin-top: 20px;
  }
  .paramList{
    display: flex;
    flex-direction: row;
    gap:10px;
  }
  .paramList p{
    font-size: 16px;
    text-align: left;
  }
  .more{
    font-size: 15px;
    left:0%;
  }
  .more2{
    font-size: 15px;
    left:0%;
  }
  .more3{
    font-size: 15px;
    left:0%;
  }
  .more4{
    font-size: 15px;
    left:0%;
  }
  .more5{
    font-size: 15px;
    left:0%;
  }
  .paramBtn{
    width: 90%;
    margin-left: 5%;
    border-radius: 20px;
  }
}
