body{
  overflow-x: hidden;
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}
.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:45%;
  font-weight: bold;
  color:#ffffff;
}
.core{
  border: 1px solid var(--color-gray-400);
  background-color: #ffffff;
  margin: 25px 10% 25px 10%;
  border-radius: 15px;
  padding:50px 80px 50px 80px;
  font-size: 15px;
  text-align: left;
}
.mainBlog{
  border-radius: 20px;
  width:80%;
  margin-left: 10%;
  height:500px;
  background-size: 100% 100%;
  position: relative;
  top:-80px
}
.mainBlog2{
  border-radius: 20px 20px 0px 0px;
  height:200px;
  background-size: 100% 100%;
}
.mainBody{
  background-color: #ffffff;
  border-radius: 20px;
  width:40%;
  height: 300px;
  position: absolute;
  right:40px;
  bottom:40px;
  text-align: left;
  padding:20px;
}
.mainBody2{
  padding:20px;
  text-align: left;
}
.tagBlue{
  background-color: #0065FF;
  border-radius: 10px;
  color: #ffffff;
  width:20%;
  font-size: 15px;
  padding:5px 10px;
  text-align: center;
}
.tagRed{
  background-color: #EB024C;
  border-radius: 10px;
  color: #ffffff;
  width:20%;
  font-size: 15px;
  padding:5px 10px;
  text-align: center;
}
.main1{
  font-weight: bold;
  font-size: 24px;
}
.main2{
  font-size: 20px;
}
.extra{
  position: relative;
}
.extra span:first-child{
  position: absolute;
  left:0%;
  color: #7D01FD;
  font-size: 18px;
  cursor: pointer;
}
.extra span:nth-child(2){
  position: absolute;
  right:0%;
  color: #A6A6A6;
  font-size: 16px;
}
.blogs{
  display: flex;
  flex-direction: row;
  width:85%;
  margin-left: 7.5%;
  gap:30px;
  margin-top:70px;
}
.blogItem{
  width: 33.3%;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding: 0px;
  height: 650px;
}


@media only screen and (max-width: 900px) {
  .banner{
    display: none;
  }
  .mainBlog{
    width:85%;
    margin-left: 7.5%;
    height:450px;
    background-size: 150% 100%;
    top:100px
  }
  .mainBlog2{
    border-radius: 20px 20px 0px 0px;
    height:200px;
    background-size: 100% 100%;
  }
  .tagBlue{
    width:40%;
  }
  .tagRed{
    width:30%;
  }
  .mainBody{
    width:70%;
    height: 300px;
  }
  .mainBody .main1{
    font-weight: bold;
    font-size: 16px;
  }
  .mainBody .main2{
    font-size: 14px;
  }
  .mainBody .extra span:first-child{
    font-size: 14px;
  }
  .mainBody .extra span:nth-child(2){
    font-size: 14px;
  }
  .mainBody2 .main1{
    font-weight: bold;
    font-size: 16px;
  }
  .mainBody2 .main2{
    font-size: 14px;
  }
  .mainBody2 .extra span:first-child{
    font-size: 14px;
  }
  .mainBody2 .extra span:nth-child(2){
    font-size: 14px;
  }
  .blogs{
    flex-direction: column;
    gap:40px;
    margin-top:180px;
  }
  .blogItem{
    width: 100%;
    padding-bottom: 50px;
    height: 100%;
  }
}
