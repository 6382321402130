body{
  overflow-x: hidden;
}
.starsIcon {
  position: absolute;
  margin: 0 !important;
  top: calc(50% - 403px);
  left: calc(50% - 680px);
  width: 1361px;
  height: 765px;
  opacity: 0.5;
  mix-blend-mode: normal;
  z-index: 0;
}
.image2Icon,
.image4Icon {
  position: absolute;
  top: 0;
  object-fit: cover;
  mix-blend-mode: lighten;
}
.image2Icon {
  left: 530.5px;
  width: 829.5px;
  height: 806px;
}
.image4Icon {
  right: -24px;
  width: 822px;
  height: 546px;
}
.foreseeDiseases,
.stayAheadBy {
  align-self: stretch;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 140%;
}
.stayAheadBy {
  font-size: var(--label-label-16-size);
  line-height: 22px;
}
.foreseeDiseasesParent {
  border-radius: var(--br-base);
  background: linear-gradient(
    261.95deg,
    rgba(87, 87, 87, 0.2),
    rgba(232, 232, 232, 0.2)
  );
  backdrop-filter: blur(24px);
  width: 312px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.frameChild {
  position: relative;
  border-radius: 50%;
  background-color: var(--color-white);
  width: 16px;
  height: 16px;
}
.ellipseWrapper,
.frameParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ellipseWrapper {
  border-radius: var(--br-29xl);
  border: 1px solid var(--color-gray-200);
  justify-content: center;
  padding: var(--padding-9xs);
}
.frameParent {
  position: absolute;
  bottom: 149px;
  left: calc(50% + 138px);
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.15);
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.eliminateHealthRisksParent {
  border-radius: var(--br-base);
  background: linear-gradient(
    261.95deg,
    rgba(87, 87, 87, 0.2),
    rgba(232, 232, 232, 0.2)
  );
  backdrop-filter: blur(24px);
  width: 328px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: var(--padding-xl);
  box-sizing: border-box;
  gap: var(--gap-9xs);
}
.ellipseContainer,
.frameGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ellipseContainer {
  border-radius: var(--br-29xl);
  border: 1px solid var(--color-gray-200);
  overflow: hidden;
  justify-content: center;
  padding: var(--padding-9xs);
}
.frameGroup {
  position: absolute;
  top: 145px;
  left: 719px;
  box-shadow: 4px 4px 32px rgba(0, 0, 0, 0.15);
  justify-content: flex-start;
  gap: var(--gap-8xs);
}
.image2Parent {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  width: 1360px;
  height: 640px;
  z-index: 1;
}
.bookYourDna,
.indiasMostTrustworthy {
  align-self: stretch;
  position: relative;
  line-height: 110%;
  font-weight: 600;
}
.indiasMostTrustworthy {
  font-size: var(--header-h2-24pt-size);
  line-height: 150%;
  font-weight: 500;
  font-family: var(--label-label-16);
}
.bookYourDnaTestNowParent {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.mobileAppStoreBadgeHome,
.mobileAppStoreBadge1Home {
  position: relative;
  border-radius: 6.97px;
  height: 57.7px;
  mix-blend-mode: normal;
  cursor: pointer;
}
.mobileAppStoreBadgeHome {
  width: 189.4px;
  overflow: hidden;
  flex-shrink: 0;
}
.mobileAppStoreBadge1Home {
  width: 168.5px;
}
.mobileAppStoreBadgeParentHome {
  flex-direction: row;
  gap: var(--gap-5xl);
}
.frameContainer,
.mobileAppStoreBadgeParentHome,
.starsParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameContainer {
  width: 512px;
  flex-direction: column;
  gap: var(--gap-29xl);
  z-index: 2;
  font-size: var(--font-size-53xl);
  font-family: var(--font-poppins);
}
.starsParent {
  position: relative;
  top: 112px;
  left: 5%;
  border-radius: var(--br-21xl);
  background: radial-gradient(50% 50%at 50% 50%, #000, #16002d 71.29%);
  width: 90%;
  height: 806px;
  overflow: hidden;
  flex-direction: row;
  padding: var(--padding-125xl) var(--padding-61xl) 0;
  box-sizing: border-box;
  gap: var(--gap-37xl);
  text-align: left;
  font-size: var(--header-h3-20pt-size);
  color: var(--color-white);
}
.imageFromRawpixelId1255100Icon,
.maleIcon {
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
.imageFromRawpixelId1255100Icon {
  align-self: stretch;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}
.maleIcon {
  width: 44px;
  height: 44px;
}
.maleWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.discoverYourBodys {
  align-self: stretch;
  position: relative;
  font-size: var(--button-label-button-18-size);
  letter-spacing: -0.01em;
  line-height: 24px;
}
.frameDiv,
.titleText,
.titleText1,
.titleText2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameDiv {
  align-self: stretch;
  justify-content: center;
  padding: var(--padding-5xl);
  gap: var(--gap-5xs);
}
.titleText,
.titleText1,
.titleText2 {
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  border: 1px solid var(--neutrals-g30);
  box-sizing: border-box;
  width: 33%;
  overflow: hidden;
  flex-shrink: 0;
  justify-content: flex-start;
}
.titleText {
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.15);
  z-index: 0;
}
.titleText1,
.titleText2 {
  z-index: 1;
}
.titleText2 {
  z-index: 2;
}
.chevronForwardCircleIcon {
  position: relative;
  width: 28px;
  height: 28px;
  overflow: hidden;
  flex-shrink: 0;
}
.navChildright {
  margin: 0 !important;
  position: absolute;
  top: calc(50% - 31px);
  right: -33px;
  border-radius: var(--br-21xl);
  background-color: var(--color-white);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gray-600);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base);
  z-index: 3;
}
.titleTextParent {
  position: relative;
  left: 0%;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--header-h2-24pt-size);
  color: var(--neutrals-black);
  margin-left: 9%;
  margin-top: 180px;
}
.herTitle {
  position: relative;
  line-height: 120%;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  width: 1168px;
}
.vectorIcon,
.vectorIcon1 {
  position: absolute;
  margin: 0 !important;
  top: 29px;
  right: 5.7px;
  width: 92px;
  height: 92px;
  z-index: 0;
}
.vectorIcon1 {
  top: 10px;
  right: 66.7px;
  width: 39px;
  height: 39px;
  z-index: 1;
}
.accessibilityIcon {
  position: relative;
  width: 16px;
  height: 16px;
  display: none;
}
.premiumDnaTest {
  flex: 1;
  position: relative;
  font-size: var(--header-h3-20pt-size);
  letter-spacing: -0.01em;
  line-height: 140%;
}
.input {
  flex: 1;
  line-height: 120%;
  font-weight: 500;
  color: var(--color-white);
}
.accessibilityIcon1,
.div,
.input {
  position: relative;
  display: none;
}
.div {
  line-height: 120%;
  font-weight: 500;
  color: var(--color-silver);
}
.accessibilityIcon1 {
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
}
.tags {
  align-self: stretch;
  border-radius: var(--br-5xs);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 0 var(--padding-5xs);
  gap: var(--gap-5xs);
}
.b {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 140%;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.tagsParent,
.wrapper {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
}
.wrapper {
  border-radius: var(--br-5xs);
  background: linear-gradient(
    95.12deg,
    rgba(255, 255, 255, 0.12),
    rgba(81, 81, 81, 0.12)
  );
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-5xs) var(--padding-base);
  font-size: var(--header-h1-28pt-size);
}
.tagsParent {
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-xs);
}
.worldsMostComprehensive {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 24px;
  text-shadow: 0 0 36px rgba(0, 0, 0, 0.75);
  z-index: 0;
}
.checkmarkIcon {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.worldsMostComprehensiveDnaParent {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 0 var(--padding-13xl);
  position: relative;
  gap: var(--gap-9xs);
}
.frameParent5 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xl);
  font-size: var(--button-label-button-18-size);
}
.accessibilityIcon2 {
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.buttonText {
  position: relative;
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 600;
  cursor: pointer;
}
.informationCircleIcon {
  position: relative;
  width: 14px;
  height: 14px;
  overflow: hidden;
  flex-shrink: 0;
}
.button {
  border-radius: var(--br-13xl);
  background: linear-gradient(
    95.12deg,
    rgba(255, 255, 255, 0.12),
    rgba(81, 81, 81, 0.12)
  );
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-5xs);
  text-align: center;
  font-size: var(--button-label-button-12-size);
}
.frameParent4 {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-13xl);
  z-index: 2;
}
.button1,
.frameParent4,
.pricingCards {
  align-self: stretch;
  display: flex;
}
.pricingCards {
  flex: 1;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background: linear-gradient(122deg, #090909, #003931);
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  position: relative;
  gap: var(--gap-37xl);
}
.button1 {
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--success-g400);
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-base);
  box-sizing: border-box;
  gap: var(--gap-base);
  text-align: center;
}
.pricingCardsParent,
.tags1 {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.pricingCardsParent {
  flex: 1;
  border-radius: var(--br-5xl);
  background-color: var(--color-white);
  border: 1px solid var(--neutrals-g30);
  box-sizing: border-box;
  height: 614px;
  overflow: hidden;
  flex-direction: column;
  z-index: 0;
}
.tags1 {
  align-self: stretch;
  border-radius: var(--br-5xs);
  flex-direction: row;
  padding: 0 0 var(--padding-5xs);
  gap: var(--gap-5xs);
  font-size: var(--label-label-16-size);
}
.essential {
  position: relative;
  font-size: var(--paragraph-paragraph-14-primary-size);
  letter-spacing: -0.01em;
  line-height: 20px;
}
.parent {
  align-self: stretch;
  border-radius: var(--br-5xs);
  background: linear-gradient(
    95.12deg,
    rgba(255, 255, 255, 0.12),
    rgba(81, 81, 81, 0.12)
  );
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-xs);
}
.checkmarkIcon5 {
  position: absolute;
  margin: 0 !important;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  z-index: 1;
}
.pricingCards1 {
  align-self: stretch;
  flex: 1;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background: linear-gradient(122deg, #090909, #290055);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  position: relative;
  gap: var(--gap-37xl);
}
.button3,
.pricingCardsGroup {
  box-sizing: border-box;
  display: flex;
}
.button3 {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--brand-v400);
  height: 80px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
  font-size: var(--label-label-16-size);
}
.pricingCardsGroup {
  flex: 1;
  border-radius: var(--br-5xl);
  border: 1px solid var(--neutrals-g30);
  height: 706px;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 1;
  font-size: var(--header-h1-28pt-size);
}
.months {
  position: relative;
  font-size: var(--label-label-16-size);
  letter-spacing: -0.01em;
  line-height: 22px;
}
.parent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
}
.perMonthThereafter {
  position: relative;
  font-size: var(--button-label-button-12-size);
  letter-spacing: -0.01em;
  line-height: 18px;
  color: rgba(254, 254, 254, 0.8);
  text-shadow: 0 0 36px rgba(0, 0, 0, 0.75);
}
.frameParent9,
.pricingCards2 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.frameParent9 {
  border-radius: var(--br-5xs);
  background: linear-gradient(
    95.12deg,
    rgba(255, 255, 255, 0.12),
    rgba(81, 81, 81, 0.12)
  );
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  justify-content: center;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-9xs);
  font-size: var(--header-h1-28pt-size);
}
.pricingCards2 {
  flex: 1;
  border-radius: var(--br-5xs) var(--br-5xs) 0 0;
  background: linear-gradient(122deg, #090909, #410015);
  justify-content: flex-start;
  padding: var(--padding-21xl) var(--padding-5xl);
  position: relative;
  gap: var(--gap-37xl);
}
.buttonText5 {
  flex: 1;
  position: relative;
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 600;
}
.button5 {
  align-self: stretch;
  border-radius: 0 0 var(--br-5xs) var(--br-5xs);
  background-color: var(--error-r300-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xl) var(--padding-base);
  gap: var(--gap-base);
  text-align: center;
}
.pricingCardsContainer {
  flex: 1;
  border-radius: var(--br-5xl);
  border: 1px solid var(--neutrals-g30);
  box-sizing: border-box;
  height: 614px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
}
.recommended {
  position: relative;
  font-size: var(--header-h3-20pt-size);
  letter-spacing: -0.01em;
  line-height: 140%;
}
.frameParent3,
.tags3 {
  display: flex;
  flex-direction: row;
}
.tags3 {
  margin: 0 !important;
  position: absolute;
  top: -24px;
  left: calc(50% - 105px);
  border-radius: var(--br-21xl);
  background-color: var(--neutrals-white-0);
  box-shadow: 0-8px 52px rgba(0, 0, 0, 0.15);
  border: 1px solid var(--neutrals-g30);
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs) var(--padding-5xl);
  gap: var(--gap-5xs);
  z-index: 3;
  color: var(--neutrals-black);
}
.frameParent3 {
  width: 1200px;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  gap: var(--gap-13xl);
  text-align: left;
  font-size: var(--label-label-16-size);
  color: var(--neutrals-white-0);
}
.herTitleParent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
  width:90%;
  margin-left: 5%;
  margin-top: 100px;
}
.materialSymbolsallInclusiveIcon {
  position: relative;
  width: 72px;
  height: 72px;
  overflow: hidden;
  flex-shrink: 0;
}
.materialSymbolsallInclusiveWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--error-r50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-29xl);
}
.frameParent12 {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-lgi);
}
.ionribbonWrapper,
.mdiideaWrapper,
.octicongoal24Wrapper {
  border-radius: var(--br-101xl);
  background-color: var(--alert-o50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-29xl);
}
.mdiideaWrapper,
.octicongoal24Wrapper {
  background-color: var(--success-g50);
}
.mdiideaWrapper {
  background-color: var(--brand-v50);
}
.frameParent11 {
  width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-size: var(--header-h2-24pt-size);
}
.herTitleGroup {
  position: relative;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-29xl);
  width:90%;
  margin-left: 5%;
  margin-top: 100px;
}
.mdifamilyTreeIcon {
  position: relative;
  width: 56px;
  height: 56px;
  overflow: hidden;
  flex-shrink: 0;
}
.mdifamilyTreeWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--brand-v300-primary);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-13xl);
}
.frameParent18,
.herTitleParent1,
.mdifamilyTreeWrapper {
  display: flex;
  justify-content: center;
}
.herTitleParent1 {
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-9xs);
}
.frameParent18 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: var(--gap-5xl);
}
.herTitle13 {
  flex: 1;
  position: relative;
  letter-spacing: -0.01em;
  line-height: 140%;
}
.chevronUpIcon {
  position: relative;
  width: 32px;
  height: 32px;
  overflow: hidden;
  flex-shrink: 0;
}
.herTitleParent2 {
  align-self: stretch;
  justify-content: center;
  gap: var(--gap-5xs);
}
.frameParent20,
.herTitleParent2,
.mdifamilyTreeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.mdifamilyTreeContainer {
  border-radius: var(--br-101xl);
  background-color: var(--brand-v50);
  justify-content: center;
  padding: var(--padding-5xs);
}
.frameParent20 {
  justify-content: flex-start;
  gap: var(--gap-base);
}
.frameParent17,
.frameWrapper,
.frameWrapper1 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper1 {
  flex-direction: row;
  flex-wrap: wrap;
  font-size: var(--button-label-button-18-size);
}
.frameParent17,
.frameWrapper {
  flex-direction: column;
}
.frameWrapper {
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  border: 1px solid var(--neutrals-g30);
  padding: var(--padding-21xl);
  font-size: var(--header-h2-24pt-size);
}
.frameParent17 {
  gap: var(--gap-5xl);
}
.fluentfoodApple20FilledWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--error-r300-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl);
}
.herTitleParent4 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.fa6SolidburgerWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--error-r50);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-5xs);
}
.frameParent24,
.frameParent25 {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xl);
}
.frameParent25 {
  flex-direction: row;
  flex-wrap: wrap;
  font-size: var(--button-label-button-18-size);
}
.frameParent24 {
  border-bottom: 1px solid var(--neutrals-g30);
  flex-direction: column;
  padding: 0 0 var(--padding-21xl);
}
.herTitle59 {
  position: relative;
  letter-spacing: -0.01em;
  line-height: 140%;
  display: inline-block;
  width: 1080px;
  height: 34px;
  flex-shrink: 0;
}
.herTitleWrapper {
  border-bottom: 1px solid var(--neutrals-g30);
  padding: 0 0 var(--padding-21xl);
}
.frameParent23,
.herTitleFrame,
.herTitleWrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameParent23 {
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  border: 1px solid var(--neutrals-g30);
  padding: var(--padding-21xl);
  gap: var(--gap-21xl);
  font-size: var(--header-h2-24pt-size);
}
.grommetIconsrunWrapper,
.materialSymbolscardiologyWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--alert-o300-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl);
}
.materialSymbolscardiologyWrapper {
  background-color: var(--alert-o50);
  padding: var(--padding-5xs);
}
.faSolidleafIcon {
  position: relative;
  width: 27px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.solarheartBoldWrapper {
  border-radius: var(--br-101xl);
  background-color: var(--success-g300-primary);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-13xl);
}
.fa6SolidburgerFrame {
  background-color: var(--success-g50);
  padding: var(--padding-5xs);
}
.fa6SolidburgerFrame,
.fontistopersonWrapper,
.healthiconsdiabetesParent,
.teenyiconsschoolSolidWrapper {
  border-radius: var(--br-101xl);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.healthiconsdiabetesParent {
  background-color: var(--brand-v50);
  padding: var(--padding-5xs);
  gap: var(--gap-5xs);
}
.fontistopersonWrapper,
.teenyiconsschoolSolidWrapper {
  background-color: var(--links-b300-primary);
  padding: var(--padding-13xl);
}
.teenyiconsschoolSolidWrapper {
  background-color: var(--links-b50);
  padding: var(--padding-5xs);
}
.fa6SolidhandshakeIcon,
.faSolidangryIcon {
  position: relative;
  width: 30px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.faSolidangryIcon {
  width: 23.3px;
}
.frameParent16 {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 80px;
  text-align: left;
  font-size: var(--header-h1-28pt-size);
}
.herTitleContainer,
.takeYourPathToPreventiveWParent {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.herTitleContainer {
  left: 0px;
  justify-content: flex-start;
  gap: var(--gap-29xl);
  width:90%;
  margin-left: 5%;
  margin-top: 100px;
}
.takeYourPathToPreventiveWParent {
  bottom: 0px;
  left: 0px;
  background-color: var(--brand-v400);
  width:100%;
  justify-content: center;
  padding: var(--padding-77xl) 0;
  box-sizing: border-box;
  gap: var(--gap-21xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  margin-top: 100px;
}
.herTitle203 {
  position: relative;
  line-height: 120%;
  text-transform: capitalize;
  font-weight: 600;
  display: inline-block;
  width: 1168px;
  z-index: 0;
}
.image5Icon,
.imageIcon {
  position: relative;
  object-fit: cover;
}
.imageIcon {
  align-self: stretch;
  max-width: 100%;
  overflow: hidden;
  height: 504px;
  flex-shrink: 0;
  z-index: 0;
}
.image5Icon {
  width: 134.3px;
  height: 32px;
}
.minRead {
  position: relative;
  font-size: var(--button-label-button-12-size);
  letter-spacing: -0.01em;
  line-height: 18px;
  color: var(--neutrals-g50-light);
  display: inline-block;
  width: 70px;
  flex-shrink: 0;
}
.readMoreParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-239xl);
  font-size: var(--button-label-button-18-size);
  color: var(--brand-v300-primary);
}
.frameParent234 {
  margin: 0 !important;
  position: absolute;
  right: 34px;
  bottom: 34px;
  border-radius: var(--br-5xl);
  background-color: var(--neutrals-white-0);
  flex-direction: column;
  justify-content: flex-start;
  padding: var(--padding-5xl);
  gap: var(--gap-13xl);
  z-index: 1;
}
.frameParent233,
.frameParent234,
.imageParent {
  display: flex;
  align-items: flex-start;
}
.imageParent {
  flex: 1;
  border-radius: var(--br-5xl);
  border: 1px solid var(--neutrals-g30);
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-5xs);
}
.frameParent233 {
  align-self: stretch;
  flex-direction: row;
  justify-content: center;
  gap: var(--gap-21xl);
  z-index: 1;
  text-align: left;
  font-size: var(--header-h2-24pt-size);
}
.navChildright1 {
  margin: 0 !important;
  top: calc(50% + 18px);
  right: -33px;
  border-radius: var(--br-21xl);
  background-color: var(--color-white);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gray-600);
  justify-content: center;
  padding: var(--padding-base);
  z-index: 2;
}
.herTitleParent29,
.navChildright1,
.navChildright2 {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.navChildright2 {
  margin: 0 !important;
  top: calc(50% + 18px);
  right: 1169px;
  border-radius: var(--br-21xl);
  background-color: var(--color-white);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.25);
  border: 1px solid var(--color-gray-600);
  justify-content: center;
  padding: var(--padding-base);
  z-index: 3;
}
.herTitleParent29 {
  top: 10690px;
  left: calc(50% - 600px);
  width: 1200px;
  justify-content: flex-start;
  gap: var(--gap-21xl);
}
.lremIpsumFaska {
  margin: 0;
}
.lremIpsumFaskaContainer {
  align-self: stretch;
  position: relative;
  line-height: 160%;
  font-weight: 500;
}
.imageIcon2 {
  position: relative;
  border-radius: var(--br-76xl);
  width: 55.1px;
  height: 55.1px;
  object-fit: cover;
}
.father {
  position: relative;
  font-size: var(--paragraph-paragraph-14-primary-size);
  letter-spacing: 0.01em;
  line-height: 100%;
  font-weight: 600;
  color: var(--color-gray-700);
}
.imageContainer,
.sameerPatelParent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.imageContainer {
  flex-direction: row;
  align-items: center;
  gap: var(--gap-base-2);
  font-size: var(--button-label-button-18-size);
  color: var(--brand-v300-primary);
}
.lremIpsumFaskaPassivhusDeParent {
  position: absolute;
  top: calc(50% - 182.3px);
  left: calc(50% - 620.9px);
  border-radius: var(--br-5xs-6);
  background-color: var(--color-white);
  border: 0.9px solid var(--neutrals-g30);
  box-sizing: border-box;
  width: 381.9px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 22.799999237060547px;
  gap: var(--gap-xl-9);
}
.imageIcon3 {
  position: relative;
  border-radius: 100px;
  width: 58px;
  height: 58px;
  object-fit: cover;
}
.imageParent1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-base);
  color: var(--brand-v300-primary);
}
.lremIpsumFaskaPassivhusDeContainer,
.lremIpsumFaskaPassivhusDeGroup {
  position: absolute;
  background-color: var(--color-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.lremIpsumFaskaPassivhusDeGroup {
  top: calc(50% - 194.5px);
  left: calc(50% - 201px);
  border-radius: var(--br-5xs);
  box-shadow: 0 40px 100px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--neutrals-g30);
  width: 402px;
  padding: var(--padding-5xl);
  gap: 22px;
  font-size: var(--button-label-button-18-size);
}
.lremIpsumFaskaPassivhusDeContainer {
  top: calc(50% - 182.3px);
  left: calc(50% + 239.1px);
  border-radius: var(--br-5xs-6);
  border: 0.9px solid var(--neutrals-g30);
  width: 381.9px;
  padding: 22.799999237060547px;
  gap: var(--gap-xl-9);
}
.imageIcon5 {
  position: relative;
  border-radius: var(--br-66xl-5);
  width: 49.6px;
  height: 49.6px;
  object-fit: cover;
}
.imageParent3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 13.68px;
  font-size: var(--button-label-button-18-size);
  color: var(--brand-v300-primary);
}
.lremIpsumFaskaPassivhusDeParent1,
.lremIpsumFaskaPassivhusDeParent2 {
  position: absolute;
  top: calc(50% - 167.2px);
  left: calc(50% + 659.1px);
  border-radius: 6.84px;
  background-color: var(--color-white);
  border: 0.9px solid var(--neutrals-g30);
  box-sizing: border-box;
  width: 343.7px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 20.520004272460938px;
  gap: 18.81px;
  font-size: 15.39px;
}
.lremIpsumFaskaPassivhusDeParent2 {
  left: calc(50% - 1002.9px);
}
.frameParent236 {
  position: relative;
  width: 1493px;
  height: 414px;
  text-align: left;
  font-size: var(--font-size-mid-1);
}
.herTitleParent30 {
  position: absolute;
  top: 10070px;
  left: -0.5px;
  border-radius: var(--br-5xs);
  width: 1440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 216px;
  box-sizing: border-box;
  gap: 28px;
}
.logo {
  position: relative;
  line-height: 100%;
  font-weight: 800;
}
.navbarLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-17xl) var(--padding-base) var(--padding-17xl)
    var(--padding-5xs);
}
.button6 {
  border-radius: var(--br-base);
  background-color: var(--brand-v400);
  justify-content: flex-start;
  padding: var(--padding-xl) var(--padding-5xl);
  text-align: left;
}
.button6,
.navbar,
.navbarLinkParent {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.navbarLinkParent {
  justify-content: center;
  gap: var(--gap-base);
  font-size: var(--button-label-button-18-size);
  color: var(--color-white);
  font-family: var(--label-label-16);
}
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-gray-800);
  backdrop-filter: blur(12px);
  border-bottom: 1px solid var(--color-gray-400);
  box-sizing: border-box;
  width: 100%;
  justify-content: space-between;
  padding: 0 var(--padding-29xl) 0 var(--padding-45xl);
  font-size: var(--font-size-13xl);
  color: var(--neutrals-white-0);
  font-family: var(--font-sora);
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  height: 10453px;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}

.navIcon {
  width:160px;
  height:40px
}

.banner{
  padding-top: 100px;
  position: relative;
  width:90%;
  margin-left: 5%;
}
.homeBanner{
  display: block;
  width: 100%;
  height: 600px;
}
.homeBannerMobile{
  display: none;
}
.title{
  position: absolute;
  top:200px;
  left:12%;
  width:30%;
  text-align: left;
}
.title p:first-child{
  font-weight: bold;
  color:#ffffff;
}
.title p:nth-child(2){
  color: #ffffff;
  font-size: 15px;
  position: relative;
  bottom: 35px;
  margin-bottom: 20px;
}
.slider1{
  background-color: #ffffff;
  border-radius: 10px;
  padding: 8px 10px;
  border: 1px solid var(--color-gray-400);
  display: flex !important;
  flex-direction: row;
  line-height: 0.7;
  min-height: 100px;
}
.slider1 img{
  position: relative;
  top:5px;
  margin-right: 10px;
}
.slider1 p:first-child{
  font-weight: bold;
  font-size: 16px;
  text-align: left;
}
.slider1 p:nth-child(2){
  font-size: 14px;
  text-align: left;
  line-height: 1;
}
.sliderCover{
  width:85%;
  margin-left: 7.5%;
  margin-right: 7.5%;
}

.secrets{
  margin-top: -60px;
  width:85%;
  margin-left: 7.5%;
}
.heading{
  font-size: 40px;
  margin-bottom: 80px;
}
.secretsFlex{
  display: flex;
  flex-direction: row;
  gap:50px;
  margin-bottom: 60px;
}
.secretsItem{
  width:33%;
}
.secretsItem img{
  width: 300px;
}
.secretsItem div{
  background-color: #ffffff;
  border-radius: 10px;
  padding:10px 20px;
  border: 1px solid var(--color-gray-400);
  text-align: left;
  min-height: 120px;
}
.secretsItem p:first-child{
  font-weight: bold;
  font-size: 16px;
}
.secretsItem p:nth-child(2){
  font-size: 14px;
}
.heading2{
  font-size: 40px;
  margin-bottom: 50px;
  margin-top: 100px;
}
.secretsFlex{
  display: flex;
  flex-direction: row;
  gap:50px;
  margin-bottom: 60px;
}
.secretsItem2{
  width:25%;
}
.secretsItem2 img{
  width: 200px;
}
.secretsItem2 div{
  background-color: #ffffff;
  border-radius: 10px;
  padding:10px 20px;
  border: 1px solid var(--color-gray-400);
  text-align: left;
  min-height: 70px;
}
.secretsItem2 p:first-child{
  font-weight: bold;
  font-size: 16px;
}
.plansBtn{
  background-color: #7D01FD;
  outline: none;
  border:none;
  width:100%;
  height: 50px;
  border-radius: 30px;
  color: #ffffff;
  font-weight: bold;
  font-size: 22px;
  cursor: pointer;
}
.plansBtn2{
  background-color: #7D01FD;
  outline: none;
  border:none;
  width:40%;
  height: 50px;
  border-radius: 15px;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  cursor:pointer;
}
.heading3{
  font-size: 40px;
  margin-bottom: 50px;
  margin-top: 120px;
}
.features{
  width:85%;
  margin-left: 7.5%;
}
.featureItem{
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
.featureLeft{
  text-align: left;
  padding-right: 10%;
  padding-top: 0px;
}
.featureLeft h6{
  color: #5E16DA;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  top: 30px;
}
.featureLeft :nth-child(2){
  font-weight: bold;
  font-size: 50px;
}
.featureLeft :nth-child(3){
  font-size: 20px;
}
.featureItem2{
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
}
.featureLeft2{
  text-align: left;
  padding-left: 11%;
  padding-top: 0px;
}
.featureLeft2 h6{
  color: #5E16DA;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  top: 30px;
}
.featureLeft2 :nth-child(2){
  font-weight: bold;
  font-size: 50px;
}
.featureLeft2 :nth-child(3){
  font-size: 20px;
}
.slider2{
  display: flex !important;
  flex-direction: row;
  gap:30px;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding:20px;
  position: relative;
  width:90%;
}
.customers{
  width:85%;
  margin-left: 7.5%;
}
.leftSlider{
  width:35%;
  position: relative;
}
.rightSlider{
  width:65%;
  position: relative;
}
.cImage{
  width:100%;
  border-radius: 15px;
}
.linkedIn{
  position: absolute;
  bottom: 20px;
  left:20px;
  display: flex;
  flex-direction: row;
  gap:20px
}
.linkedIn img{
  cursor: pointer;
}
.linkedIn span{
  font-size: 25px;
  font-weight: bold;
  color: white;
  position: relative;
  top:4px;
}
.quote1{
  position: absolute;
  top:20px;
}
.quote2{
  position: absolute;
  bottom:20px;
  right:60px;
}
.rightSlider p{
  margin-top: 100px;
  font-size: 28px;
  text-align: left;
}
.params{
  width:85%;
  margin-left: 7.5%;
  margin-top: 150px;
}
.paramFlex{
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.paramFlex2{
  display: flex;
  flex-direction: row;
  gap: 50px;
  width:75%;
  margin-left: 17.5%;
  margin-top: 50px;
}
.paramItem{
  width:33.3%;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding: 10px 40px;
}
.mainIcon{
  margin-top: 20px;
}
.paramItem h6{
  margin:0px;
  font-size: 22px;
  margin-top: 10px;
  margin-bottom: 25px;
}
.paramList{
  display: flex;
  flex-direction: row;
  gap:10px;
}
.paramList p{
  font-size: 16px;
}
.more{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #EB024C;
  cursor: pointer;
}
.more2{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #FD9100;
  cursor: pointer;
}
.more3{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #00A38A;
  cursor: pointer;
}
.more4{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #7D01FD;
  cursor: pointer;
}
.more5{
  font-size: 16px;
  position: relative;
  left:-5%;
  color: #0065FF;
  cursor: pointer;
}
.paramBtn{
  background-color: #7D01FD;
  border-radius: 30px;
  color:#ffffff;
  width: 30%;
  font-size: 20px;
  margin-left: 35%;
  margin-top: 50px;
  padding: 10px 0px;
  cursor: pointer;
}
.faq{
  width:85%;
  margin-left: 7.5%;
}
.faqFlex{
  display: flex;
  flex-direction: row;
  gap:40px;
}
.faqItem{
  text-align: left;
  width:33.3%;
}
.faqItem p:nth-child(2){
  font-size: 20px;
  font-weight: bold;
  position: relative;
  top:-10px;
}
.faqItem p:nth-child(3){
  font-size: 18px;
  position: relative;
  top:-20px;
}
.accrediations{
  width: 85%;
  margin-left: 7.5%;
}
.slider3{
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid var(--color-gray-400);
  padding:20px;
  position: relative;
}
.slider3 img{
  width:100%;
}
.steps{
  width:85%;
  margin-left: 7.5%;
}
.stepsMain{
  display: flex;
  flex-direction: row;
  gap:30px;
}
.leftStep{
  width:10%;
  position: relative;
}
.stepImage{
  width:45%;
}
.stepInfo{
  width:45%;
  background-color: #ffffff;
  border-radius: 20px;
  padding:20px;
  border: 1px solid var(--color-gray-400);
  text-align: left;
  height:400px;
  margin-top: 100px;
}
.stepInfo p:nth-child(2){
  font-weight: bold;
  font-size: 30px;
  margin-top: 0px;
}
.stepInfo p:nth-child(3){
  font-size: 26px;
}
.stepLine{
  height:90%;
  background-color:rgba(125,125,125,0.5);
  width:1px;
  position: relative;
  top:10%;
  left: 40px;
}
.step1{
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  width:60px;
  height:60px;
  cursor: pointer;
  position: absolute;
  top:0px;
  left:10px;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step2{
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  width:60px;
  height:60px;
  cursor: pointer;
  position: absolute;
  top:130px;
  left:10px;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step3{
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  width:60px;
  height:60px;
  cursor: pointer;
  position: absolute;
  top:260px;
  left:10px;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step4{
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  width:60px;
  height:60px;
  cursor: pointer;
  position: absolute;
  top:390px;
  left:10px;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}
.step5{
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid var(--color-gray-400);
  width:60px;
  height:60px;
  cursor: pointer;
  position: absolute;
  top:520px;
  left:10px;
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .navbar{
    padding: 0 10px;
  }
  .frameContainer {
    width: 100%;
    flex-direction: column;
    gap: var(--gap-29xl);
    z-index: 2;
    font-size: 35px;
    font-family: var(--font-poppins);
  }
  .bookYourDnaTestNowParent {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-base);
  }
  .starsParent {
    padding: 100px 50px 100px 50px;
    height: 606px;
  }
  
  .mobileAppStoreBadgeParentHome {
    flex-direction: column;
    gap: var(--gap-5xl);
  }
  .titleTextParent {
    position: relative;
    left: 0%;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: var(--gap-13xl);
    text-align: left;
    font-size: var(--header-h2-24pt-size);
    color: var(--neutrals-black);
    margin-left: 10%;
  }
  .titleText,
  .titleText1,
  .titleText2 {
    border-radius: var(--br-5xl);
    background-color: var(--neutrals-white-0);
    border: 1px solid var(--neutrals-g30);
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    flex-shrink: 0;
    justify-content: flex-start;
  }
  
  .herTitle {
    position: relative;
    line-height: 120%;
    text-transform: capitalize;
    font-weight: 600;
    display: inline-block;
    width: 100%;
    font-size: 40px;
  }
  .frameParent3 {
    width: 90%;
    flex-direction: column;
    margin-left: 5%;
  }
  .herTitleGroup {
    position: relative;
    left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: var(--gap-29xl);
    width:90%;
    margin-left: 5%;
  }
  .herTitleContainer {
    left: 0px;
    justify-content: flex-start;
    gap: var(--gap-29xl);
    width:90%;
    margin-left: 5%;
  }
  .pricingCardsGroup {
    width:100%
  }
  .b{
    font-size: 20px;
  }
  .frameParent11 {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 50px;
  }
  .frameParent16 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 80px;
    text-align: left;
    font-size: var(--header-h1-28pt-size);
  }
  .website {
    position: relative;
    background-color: var(--color-whitesmoke);
    width: 100%;
    height:100%;
    text-align: center;
    font-size: var(--font-size-29xl);
    color: var(--color-black);
    font-family: var(--label-label-16);
  }
  .takeYourPathToPreventiveWParent{
    height: 500px;
  }

  .slider1{
    min-height: 120px;
  }
  .slider1 img{
    position: relative;
    top:5px;
    margin-right: 10px;
  }
  .slider1 p:first-child{
    font-size: 14px;
    line-height: 1;
  }
  .slider1 p:nth-child(2){
    font-size: 13px;
    line-height: 1;
  }
  .secrets{
    margin-top: 70px;
    width:90%;
    margin-left: 5%;
  }
  .heading{
    font-size: 25px;
    margin-bottom: 40px;
  }
  .secretsFlex{
    flex-direction: row;
    gap:40px;
    margin-bottom: 60px;
    padding-right: 5%;
    position: relative;
  }
  .secretsItem{
    width:33%;
  }
  .secretsItem img{
    width: 70px;
  }
  .secretsItem div{
    padding:5px 10px;
    min-height: 60px;
    width: 100%;
  }
  .secretsItem p:first-child{
    font-size: 9px;
  }
  .secretsItem p:nth-child(2){
    display: none;
  }
  .heading2{
    font-size: 25px;
    margin-bottom: 40px;
    margin-top: 50px;
  }
  .secretsItem2{
    width:23%;
  }
  .secretsItem2 img{
    width: 50px;
  }
  .secretsItem2 div{
    padding:0px 10px;
    min-height: 80px;
  }
  .secretsItem2 p:first-child{
    font-weight: bold;
    font-size: 7px;
  }
  .plansBtn{
    position: relative;
    top:-40px;
  }
  .plansBtn2{
    width:100%;
    height: 50px;
    font-size: 18px;
  }
  .heading3{
    font-size: 25px;
    margin-bottom: -20px;
    margin-top: 50px;
  }
  .features{
    width:90%;
    margin-left: 5%;
  }
  .featureItem{
    flex-direction: column;
    margin-bottom: 80px;
  }
  .featureLeft{
    text-align: left;
    padding-right: 10%;
    padding-top: 0px;
  }
  .featureLeft h6{
    font-size: 18px;
    top: 30px;
  }
  .featureLeft :nth-child(2){
    font-size: 24px;
  }
  .featureLeft :nth-child(3){
    font-size: 12px;
  }
  .featureItem2{
    flex-direction: column;
    margin-bottom: 80px;
  }
  .featureLeft2{
    text-align: left;
    padding-left: 0%;
    padding-top: 0px;
    margin-top: -40px;
    margin-bottom: -70px;
  }
  .featureLeft2 h6{
    font-size: 18px;
    top: 30px;
  }
  .featureLeft2 :nth-child(2){
    font-size: 24px;
  }
  .featureLeft2 :nth-child(3){
    font-size: 12px;
  }
  .featureRight img{
    width:100%;
    margin-top: 40px
  }
  .featureRight2 img{
    width:100%;
  }
  .slider2{
    display: flex !important;
    flex-direction: column;
    gap:30px;
    padding:20px;
    width:100%;
    top:60px;
  }
  .customers{
    width:90%;
    margin-left: 5%;
    margin-top: 100px;
  }
  .leftSlider{
    width:100%;
  }
  .rightSlider{
    width:100%;
    position: relative;
    height: 100%;
    padding-bottom: 20px;
    min-height: 350px;
  }
  .cImage{
    width:100%;
    border-radius: 15px;
  }
  .linkedIn{
    bottom: 10px;
    left:10px;
    gap:10px
  }
  .linkedIn img{
    cursor: pointer;
  }
  .linkedIn span{
    font-size: 15px;
    top:12px;
  }
  .quote1{
    top:0px;
    width: 30px;
  }
  .quote2{
    bottom:60px;
    right:10px;
    width:30px;
  }
  .rightSlider p{
    margin-top: 50px;
    font-size: 13px;
  }
  .accrediations{
    width: 90%;
    margin-left: 5%;
    margin-top: 120px;
  }
  .slider3{
    margin-top: 50px;
  }
  .slider3 img{
    width:100%;
  }
  .paramFlex{
    flex-direction: column;
    gap: 50px;
    width:85%;
  }
  .paramFlex2{
    flex-direction: column;
    gap: 50px;
    width:85%;
    margin-left: 0%;
    margin-top: 50px;
  }
  .paramItem{
    width:100%;
    padding: 10px 20px;
  }
  .mainIcon{
    margin-top: 20px;
  }
  .paramList{
    display: flex;
    flex-direction: row;
    gap:10px;
  }
  .paramList p{
    font-size: 16px;
    text-align: left;
  }
  .more{
    font-size: 15px;
    left:0%;
  }
  .more2{
    font-size: 15px;
    left:0%;
  }
  .more3{
    font-size: 15px;
    left:0%;
  }
  .more4{
    font-size: 15px;
    left:0%;
  }
  .more5{
    font-size: 15px;
    left:0%;
  }
  .paramBtn{
    width: 90%;
    margin-left: 5%;
    border-radius: 20px;
  }
  .faq{
    width:90%;
    margin-left: 5%;
    margin-top: 100px;
  }
  .faqFlex{
    margin-top: 50px;
    flex-direction: column;
    gap:0px;
  }
  .faqItem{
    width:100%;
  }
  .faqItem p:nth-child(2){
    font-size: 16px;
    position: relative;
    top:-10px;
  }
  .faqItem p:nth-child(3){
    font-size: 13px;
    position: relative;
    top:-20px;
  }
  .titleTextParent{
    display: none;
  }
  .steps{
    width:90%;
    margin-left:5%;
    margin-top: 100px;
  }
  .stepsMain{
    flex-direction: column;
    gap:15px;
    margin-top: 80px;
  }
  .leftStep{
    width:10%;
    position: relative;
  }
  .stepImage{
    width:80%;
    margin-left: 20%;
  }
  .stepImage img{
    width:100%;
  }
  .stepInfo{
    width:85%;
    padding:0px 15px;
    height:100%;
    margin-top: 10px;
    margin-left: 5%;
  }
  .stepInfo img{
    width:30px;
  }
  .stepInfo p:nth-child(2){
    font-size: 16px;
    margin-top: 0px;
  }
  .stepInfo p:nth-child(3){
    font-size: 13px;
  }
  .stepLine{
    height:40%;
    background-color:rgba(125,125,125,0.5);
    width:1px;
    position: absolute;
    top:10%;
    left: 20px;
    height: 240px;
  }
  .step1{
    width:30px;
    height:30px;
    top:0px;
    left:5px;
    font-size: 20px;
  }
  .step2{
    width:30px;
    height:30px;
    top:60px;
    left:5px;
    font-size: 20px;
  }
  .step3{
    width:30px;
    height:30px;
    top:120px;
    left:5px;
    font-size: 20px;
  }
  .step4{
    width:30px;
    height:30px;
    top:180px;
    left:5px;
    font-size: 20px;
  }
  .step5{
    width:30px;
    height:30px;
    top:240px;
    left:5px;
    font-size: 20px;
  }
  .banner{
    padding-top: 100px;
    position: relative;
  }
  .homeBanner{
    width: 90%;
    height: 400px;
    display: none;
  }
  .homeBannerMobile{
    display: block;
    width: 100%;
    height: 400px;
    margin-left: 0%;
    margin-bottom: 10px;
  }
  .title{
    position: absolute;
    top:200px;
    left:12%;
    width:60%;
    text-align: left;
  }
  .title p:first-child{
    font-size: 18px;
  }
  .title p:nth-child(2){
    color: #ffffff;
    font-size: 14px;
    position: relative;
    bottom: 0px;
    margin-bottom: 20px;
  }
  .mobileAppStoreBadgeHome,
  .mobileAppStoreBadge1Home {
    height: 57.7px;
    mix-blend-mode: normal;
    cursor: pointer;
  }
  .mobileAppStoreBadgeHome {
    width: 150px;
    overflow: hidden;
    flex-shrink: 0;
  }
  .mobileAppStoreBadge1Home {
    width: 150px;
    position: relative;
    top:-20px;
  }
  
}


@media only screen and (max-width: 400px) {
  .secretsFlex{
    padding-left: 2%;
    padding-right: 2%;
    gap:10px;
  }
  .secretsItem{
    width:30%;
    margin:0%;
  }
  .secretsItem img{
    width: 70px;
  }
  .secretsItem div{
    padding:5px 10px;
    min-height: 80px;
    width: 80%;
  }
}