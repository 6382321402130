body{
  overflow-x: hidden;
}
.website {
  position: relative;
  background-color: var(--color-whitesmoke);
  width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-black);
  font-family: var(--label-label-16);
}
.banner{
  padding-top: 100px;
  position: relative;
}
.banner img{
  width: 90%;
  height: 400px;
}
.title{
  position: absolute;
  top:250px;
  left:35%;
  font-weight: bold;
  color:#ffffff;
}
.core{
  border: 1px solid var(--color-gray-400);
  background-color: #ffffff;
  margin: 25px 10% 25px 10%;
  border-radius: 15px;
  padding:50px 80px 50px 80px;
  font-size: 15px;
  text-align: left;
}
.main{
  width:85%;
  margin-left: 7.5%;
  padding-top: 150px;
}
.tag{
  background-color: #DD572C;
  color: white;
  width:12%;
  margin-left: 44%;
  border-radius: 10px;
  font-size: 24px;
  padding:10px 0px 10px 0px;
}
.helper{
  position: relative;
  width: 20%;
  height: 50px;
  margin-left: 40%;
  margin-top: 30px;
}
.helper span:first-child{
  position: absolute;
  left:0%;
  font-size: 16px;
  color:rgba(1,2,14,0.4);
}
.helper span:nth-child(2){
  position: absolute;
  right:0%;
  font-size: 16px;
  color:rgba(1,2,14,0.4);
}
.heading{
  font-weight: bold;
  font-size: 40px;
  margin-bottom: 40px;
}
.mainImage{
  width:100%;
  border-radius: 20px;
  height: 500px;
}
.body{
  margin-top: 50px;
  font-size: 22px !important;
  line-height: 1.5;
  text-align: left;
}





@media only screen and (max-width: 900px) {
  .tag{
    width:70%;
    margin-left: 15%;
    font-size: 22px;
  }
  .helper{
    width: 80%;
    margin-left: 10%;
  }
  .helper span:first-child{
    font-size: 14px;
  }
  .helper span:nth-child(2){
    font-size: 14px;
  }
  .heading{
    font-size: 25px;
  }
  .mainImage{
    height: 300px;
  }
  .body{
    margin-top: 20px;
  }
}
